@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(/assets/css/fonts/lato.bd03a2cc277bbbc338d464e679fe9942.woff2) format("woff2"), url(/assets/css/fonts/lato.27bd77b9162d388cb8d4c4217c7c5e2a.woff) format("woff"); }

@font-face {
  font-family: 'Lato';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url(/assets/css/fonts/lato-bold.cccb897485813c7c256901dbca54ecf2.woff2) format("woff2"), url(/assets/css/fonts/lato-bold.d878b6c29b10beca227e9eef4246111b.woff) format("woff"); }

@font-face {
  font-family: 'unoauto';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url(/assets/css/fonts/unoauto.715eed0ffea325634435be22eb3162d3.woff2) format("woff2"), url(/assets/css/fonts/unoauto.1dffaddaf3685f3c2307da37f1712669.woff) format("woff"); }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'unoauto';
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon {
  font-family: 'Lato' !important; }

.icon:before {
  font-family: 'unoauto'; }

.icon-velocimetro:before {
  content: "\E900"; }

.icon-cerrar::before {
  content: "\E901"; }

.icon-berlina:before, .icon-berlinas:before {
  content: "\E902"; }

.icon-cubo:before {
  content: "\E903"; }

.icon-tic-cuadro-vacio:before {
  content: "\E904"; }

.icon-camara:before {
  content: "\E905"; }

.icon-automatico:before {
  content: "\E906"; }

.icon-manual:before {
  content: "\E907"; }

.icon-carrito:before {
  content: "\E908"; }

.icon-tic-circulo-lleno:before {
  content: "\E909"; }

.icon-tic-circulo-vacio:before {
  content: "\E90A"; }

.icon-deportivo:before, .icon-deportivos:before {
  content: "\E90B"; }

.icon-descapotable:before, .icon-descapotables:before {
  content: "\E90C"; }

.icon-diesel:before {
  content: "\E90D"; }

.icon-dimensiones:before {
  content: "\E90E"; }

.icon-electrico:before {
  content: "\E90F"; }

.icon-embudo:before {
  content: "\E910"; }

.icon-emisiones:before {
  content: "\E911"; }

.icon-excl:before {
  content: "\E912"; }

.icon-familiar:before, .icon-familiares:before {
  content: "\E913"; }

.icon-flecha-abajo:before {
  content: "\E914"; }

.icon-flecha-arriba:before {
  content: "\E915"; }

.icon-flecha-der:before {
  content: "\E916"; }

.icon-flecha-izq:before {
  content: "\E917"; }

.icon-gas:before {
  content: "\E918"; }

.icon-gasolina:before {
  content: "\E919"; }

.icon-goma:before {
  content: "\E91A"; }

.icon-hibrido:before {
  content: "\E91B"; }

.icon-info:before {
  content: "\E91C"; }

.icon-menu::before {
  content: "\E91D"; }

.icon-monovolumen:before, .icon-monovolumenes:before {
  content: "\E91E"; }

.icon-papelera:before {
  content: "\E91F"; }

.icon-usuario:before {
  content: "\E920"; }

.icon-puerta:before {
  content: "\E921"; }

.icon-sobre::before {
  content: "\E922"; }

.icon-combustible:before {
  content: "\E923"; }

.icon-suv:before, .icon-todoterrenos:before {
  content: "\E924"; }

.icon-telefono:before {
  content: "\E925"; }

.icon-tic-cuadro-lleno:before {
  content: "\E926"; }

.icon-tic:before {
  content: "\E927"; }

.icon-ubicacion:before {
  content: "\E928"; }

.icon-pequeno:before, .icon-compactos:before {
  content: "\E929"; }

.icon-industrial:before {
  content: "\E92A"; }

.icon-circulo:before {
  content: "\E92B"; }

.icon-circulo_check:before {
  content: "\E92C"; }

.icon-mano:before {
  content: "\E92D"; }

.icon-cama_oferta:before {
  content: "\E92E"; }

.icon-like-abajo:before {
  content: "\E92F"; }

.icon-like-arriba:before {
  content: "\E930"; }

.icon-etiqueta:before {
  content: "\E931"; }

.icon-oro:before {
  content: "\E932"; }

.icon-reloj:before {
  content: "\E933"; }

* {
  margin: 0;
  padding: 0;
  font-weight: 400;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  /*.rem(font-size, 10);*/
  font-size: 16px;
  font-size: 1.6rem;
  font-family: 'Lato'; }

*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  color: #212121; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

ul li {
  list-style: none; }

a {
  color: #1976d2;
  cursor: pointer;
  text-decoration: none;
  background: transparent;
  outline: 0; }
  a:hover, a:focus {
    color: #448aff;
    text-decoration: none;
    outline: 0; }
  a:active, a:focus {
    outline: 0;
    background: transparent; }

img {
  border: 0;
  vertical-align: middle; }

img:focus {
  border: none; }

h1 {
  /*.rem(font-size, 10);*/
  font-size: 32px;
  font-size: 3.2rem; }

h2 {
  /*.rem(font-size, 10);*/
  font-size: 28px;
  font-size: 2.8rem; }

h3, h4, h5, h6 {
  /*.rem(font-size, 10);*/
  font-size: 16px;
  font-size: 1.6rem; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }
  button[disabled], html input[type="button"][disabled], input[type="reset"][disabled], input[type="submit"][disabled] {
    cursor: default; }

textarea {
  overflow: auto; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

:focus {
  outline: 0;
  border: none; }

.hidden {
  display: none !important; }

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-nh1g-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

.modal {
  display: none;
  overflow: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  outline: 0;
  background: rgba(0, 0, 0, 0.8); }
  .modal .modal-dialog {
    width: 100%;
    margin: auto;
    /*.rem(font-size, 10);*/
    margin-top: 50px;
    margin-top: 5rem;
    background: #fff;
    border-radius: 6px;
    position: relative; }
  .modal .cerrar {
    color: #fff;
    position: absolute;
    /*.rem(font-size, 10);*/
    top: -30px;
    top: -3rem;
    /*.rem(font-size, 10);*/
    right: 10px;
    right: 1rem; }

.modal .contenedor, .contactar .contenedor {
  /*.rem(font-size, 10);*/
  padding: 20px;
  padding: 2rem; }
  .modal .contenedor .titulo, .contactar .contenedor .titulo {
    float: left;
    width: 100%;
    border-bottom: 1px solid #e7ebef;
    /*.rem(font-size, 10);*/
    padding-bottom: 20px;
    padding-bottom: 2rem;
    /*.rem(font-size, 10);*/
    margin-bottom: 20px;
    margin-bottom: 2rem; }
    .modal .contenedor .titulo p, .contactar .contenedor .titulo p {
      float: left;
      /*.rem(font-size, 10);*/
      font-size: 20px;
      font-size: 2rem; }
    .modal .contenedor .titulo span, .contactar .contenedor .titulo span {
      display: block;
      /*.rem(font-size, 10);*/
      font-size: 28px;
      font-size: 2.8rem;
      color: #ef6c00; }
    .modal .contenedor .titulo a, .contactar .contenedor .titulo a {
      float: left;
      width: 100%;
      /*.rem(font-size, 10);*/
      margin-top: 10px;
      margin-top: 1rem; }
  .modal .contenedor .datos, .contactar .contenedor .datos {
    margin: auto; }
    @media (min-width: 500px) {
      .modal .contenedor .datos, .contactar .contenedor .datos {
        /*.rem(font-size, 10);*/
        width: 500px;
        width: 50rem; } }
    .modal .contenedor .datos .form-group, .contactar .contenedor .datos .form-group {
      display: inline-block;
      position: relative;
      width: 100%; }
      .modal .contenedor .datos .form-group input[type="text"], .modal .contenedor .datos .form-group textarea, .contactar .contenedor .datos .form-group input[type="text"], .contactar .contenedor .datos .form-group textarea {
        /*.rem(font-size, 10);*/
        margin-bottom: 15px;
        margin-bottom: 1.5rem;
        width: 100%;
        /*.rem(font-size, 10);*/
        padding-right: 35px;
        padding-right: 3.5rem; }
      .modal .contenedor .datos .form-group textarea, .contactar .contenedor .datos .form-group textarea {
        /*.rem(font-size, 10);*/
        height: 100px;
        height: 10rem; }
      .modal .contenedor .datos .form-group span, .contactar .contenedor .datos .form-group span {
        position: absolute;
        /*.rem(font-size, 10);*/
        top: 7px;
        top: 0.7rem;
        /*.rem(font-size, 10);*/
        right: 10px;
        right: 1rem;
        color: #b6b6b6;
        /*.rem(font-size, 10);*/
        font-size: 20px;
        font-size: 2rem; }
      .modal .contenedor .datos .form-group.privacidad, .contactar .contenedor .datos .form-group.privacidad {
        float: none;
        margin: 0;
        /*.rem(font-size, 10);*/
        margin-bottom: 20px;
        margin-bottom: 2rem;
        width: 100%; }
        .modal .contenedor .datos .form-group.privacidad input, .contactar .contenedor .datos .form-group.privacidad input {
          position: relative;
          /*.rem(font-size, 10);*/
          top: 3px;
          top: 0.3rem; }
    .modal .contenedor .datos p, .contactar .contenedor .datos p {
      text-align: center; }
      .modal .contenedor .datos p span, .contactar .contenedor .datos p span {
        display: block;
        /*.rem(font-size, 10);*/
        font-size: 32px;
        font-size: 3.2rem; }
    .modal .contenedor .datos .selectFiltro, .contactar .contenedor .datos .selectFiltro {
      float: none;
      /*.rem(font-size, 10);*/
      margin-bottom: 15px;
      margin-bottom: 1.5rem; }
      .modal .contenedor .datos .selectFiltro p, .contactar .contenedor .datos .selectFiltro p {
        text-align: left; }
    .modal .contenedor .datos.success, .contactar .contenedor .datos.success {
      clear: both;
      width: 100%;
      background: #dff0d8;
      border: 1px solid #468847;
      /*.rem(margin, 10, 10);*/
      padding: 5px 10px;
      padding: 0.5rem 1rem;
      /*.rem(font-size, 10);*/
      padding-bottom: 30px;
      padding-bottom: 3rem;
      /*.rem(font-size, 10);*/
      margin-bottom: 20px;
      margin-bottom: 2rem; }
      .modal .contenedor .datos.success p span, .contactar .contenedor .datos.success p span {
        /*.rem(font-size, 10);*/
        font-size: 28px;
        font-size: 2.8rem;
        /*.rem(margin, 10, 10);*/
        margin: 10px 0px;
        margin: 1rem 0rem; }
      .modal .contenedor .datos.success .icon, .contactar .contenedor .datos.success .icon {
        font-weight: 700;
        color: #468847; }
      .modal .contenedor .datos.success a, .contactar .contenedor .datos.success a {
        display: block;
        text-align: center; }
  .modal .contenedor .btn, .contactar .contenedor .btn {
    display: block;
    margin: auto;
    /*.rem(font-size, 10);*/
    margin-bottom: 15px;
    margin-bottom: 1.5rem; }
  .modal .contenedor .legal, .contactar .contenedor .legal {
    /*.rem(font-size, 10);*/
    margin-bottom: 20px;
    margin-bottom: 2rem;
    color: #727272;
    text-align: left !important; }

.modal.active, .contactar.active {
  display: block; }

input[type="text"] {
  display: block;
  /*.rem(font-size, 10);*/
  height: 35px;
  height: 3.5rem;
  /*.rem(margin, 10, 10);*/
  padding: 6px 12px;
  padding: 0.6rem 1.2rem;
  color: #4d4d4d;
  border: 1px solid #b6b6b6;
  border-radius: 3px;
  /*.rem(font-size, 10);*/
  width: 225px;
  width: 22.5rem; }

*::-webkit-input-placeholder {
  color: #b6b6b6; }

*:-moz-placeholder {
  color: #b6b6b6; }

*::-moz-placeholder {
  color: #b6b6b6; }

*:-ms-input-placeholder {
  color: #b6b6b6; }

.checkbox, .radio {
  position: relative;
  float: left;
  width: 100%; }
  .checkbox input, .radio input {
    display: none; }
  .checkbox label, .radio label {
    display: block;
    cursor: pointer;
    width: auto;
    padding: 0;
    /*.rem(font-size, 10);*/
    padding-top: 6px;
    padding-top: 0.6rem;
    /*.rem(font-size, 10);*/
    padding-left: 35px;
    padding-left: 3.5rem;
    color: #4d4d4d;
    /*.rem(font-size, 10);*/
    min-height: 32px;
    min-height: 3.2rem; }
    .checkbox label .icon:before, .radio label .icon:before {
      content: "\E904";
      position: absolute;
      top: 0;
      left: 0;
      /*.rem(font-size, 10);*/
      font-size: 28px;
      font-size: 2.8rem;
      color: #4d4d4d !important; }
    .checkbox label input:checked + .icon:before, .radio label input:checked + .icon:before {
      content: "\E926"; }

.radio label .icon:before {
  content: "\E90A"; }

.radio label input:checked + .icon:before {
  content: "\E909"; }

.selectFiltro {
  float: left;
  position: relative;
  background: #fff;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  .selectFiltro p {
    width: 100%;
    /*.rem(font-size, 10);*/
    height: 37px;
    height: 3.7rem;
    border: 1px solid #b6b6b6;
    border-radius: 3px;
    color: #4d4d4d;
    background: url(/assets/img/flecha.d846dd0f59f11fc450e06cec5b703a84.png) no-repeat 95% 15px;
    cursor: pointer;
    /*.rem(margin, 10, 10);*/
    padding: 8px 35px;
    padding: 0.8rem 3.5rem;
    padding-bottom: 0;
    /*.rem(font-size, 10);*/
    padding-left: 5px;
    padding-left: 0.5rem; }
  .selectFiltro ul {
    display: none;
    position: fixed !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999999;
    border: 1px solid #b6b6b6;
    background: #fff;
    overflow-y: scroll;
    overflow-x: hidden; }
    .selectFiltro ul li {
      cursor: pointer;
      color: #4d4d4d;
      /*.rem(font-size, 10);*/
      padding: 10px;
      padding: 1rem; }
      .selectFiltro ul li input {
        width: 100%; }
      .selectFiltro ul li:nth-of-type(1) {
        text-align: right;
        /*.rem(font-size, 10);*/
        padding: 10px;
        padding: 1rem;
        padding-bottom: 0;
        padding-left: 0; }
        .selectFiltro ul li:nth-of-type(1) .icon:before {
          font-weight: 700;
          color: #1976d2; }
  .selectFiltro.disabled p {
    background-color: #e7ebef;
    color: #b6b6b6;
    cursor: default; }
  .selectFiltro.disabled ul {
    display: none !important; }
  .selectFiltro.seleccionado p {
    border: 1px solid #212121;
    color: #212121; }

.error input[type="text"], .error .selectFiltro > p {
  border: 1px solid #b74b4b; }

.error > p:not(.legal) {
  color: #b74b4b;
  text-align: left;
  /*.rem(font-size, 10);*/
  font-size: 14px;
  font-size: 1.4rem;
  margin: 0;
  /*.rem(font-size, 10);*/
  margin-top: 5px;
  margin-top: 0.5rem; }

.btn {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  touch-action: manipulation;
  /*.rem(font-size, 10);*/
  height: 37px;
  height: 3.7rem;
  border-radius: 3px;
  /*.rem(margin, 10, 10);*/
  padding: 8px 30px;
  padding: 0.8rem 3rem;
  padding-bottom: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  /*.rem(font-size, 10);*/
  font-size: 16px;
  font-size: 1.6rem; }
  .btn.btn-orange {
    background: #ef6c00;
    border: 1px solid #e65100;
    color: #fff; }
  .btn.btn-blue {
    background: #1976d2;
    border: 1px solid #1976d2;
    color: #fff; }
  .btn.disabled {
    background: #b6b6b6;
    border-color: #b6b6b6; }

.publicidad {
  float: left;
  /*.rem(font-size, 10);*/
  margin-bottom: 20px;
  margin-bottom: 2rem; }

.text-publi {
  float: left;
  width: 100%;
  background: #e7ebef;
  text-align: center;
  /*.rem(font-size, 10);*/
  font-size: 10px;
  font-size: 1rem; }

.navbar {
  /*.rem(font-size, 10);*/
  height: 60px;
  height: 6rem;
  background: #fff;
  border-bottom: 1px solid #1976d2;
  /*.rem(font-size, 10);*/
  padding: 15px;
  padding: 1.5rem;
  position: relative; }
  .navbar .logo {
    /*.rem(font-size, 10);*/
    width: 118px;
    width: 11.8rem;
    height: auto; }
  .navbar .menu {
    float: right; }
    .navbar .menu .icon {
      /*.rem(font-size, 10);*/
      font-size: 28px;
      font-size: 2.8rem; }
  .navbar .enlaces {
    display: none; }
  .navbar.active .menu .icon:before {
    content: "\E901"; }
  .navbar.active .enlaces {
    display: block;
    position: absolute;
    /*.rem(font-size, 10);*/
    top: 50px;
    top: 5rem;
    /*.rem(font-size, 10);*/
    right: 10px;
    right: 1rem;
    z-index: 9999;
    /*.rem(font-size, 10);*/
    width: 200px;
    width: 20rem;
    background: #fff;
    border: 1px solid #b6b6b6;
    /*.rem(font-size, 10);*/
    padding: 15px;
    padding: 1.5rem; }
    .navbar.active .enlaces a {
      display: block;
      /*.rem(font-size, 10);*/
      font-size: 16px;
      font-size: 1.6rem;
      margin: 0;
      /*.rem(font-size, 10);*/
      margin-bottom: 10px;
      margin-bottom: 1rem;
      /*.rem(font-size, 10);*/
      margin-left: 5px;
      margin-left: 0.5rem; }

.breadcrumb {
  float: left;
  width: 100%;
  /*.rem(font-size, 10);*/
  padding: 20px;
  padding: 2rem;
  /*.rem(font-size, 10);*/
  font-size: 14px;
  font-size: 1.4rem;
  color: #4d4d4d; }
  .breadcrumb a {
    /*.rem(font-size, 10);*/
    font-size: 14px;
    font-size: 1.4rem; }

.subir {
  position: fixed;
  /*.rem(font-size, 10);*/
  bottom: 10px;
  bottom: 1rem;
  /*.rem(font-size, 10);*/
  right: 10px;
  right: 1rem;
  z-index: 999999;
  border-radius: 22px;
  border: 1px solid #b6b6b6;
  /*.rem(font-size, 10);*/
  padding: 10px;
  padding: 1rem;
  background: rgba(231, 235, 239, 0.8);
  display: none; }
  .subir .icon {
    /*.rem(font-size, 10);*/
    font-size: 20px;
    font-size: 2rem;
    font-weight: 700; }
  .ficha .subir {
    /*.rem(font-size, 10);*/
    bottom: 80px;
    bottom: 8rem; }

.cookie {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #b6b6b6;
  z-index: 9999999;
  /*.rem(font-size, 10);*/
  font-size: 14px;
  font-size: 1.4rem;
  /*.rem(margin, 10, 10);*/
  padding: 5px 30px;
  padding: 0.5rem 3rem;
  /*.rem(font-size, 10);*/
  padding-bottom: 10px;
  padding-bottom: 1rem; }
  .cookie a {
    /*.rem(font-size, 10);*/
    font-size: 14px;
    font-size: 1.4rem; }
  .cookie .cerrar {
    position: absolute;
    /*.rem(font-size, 10);*/
    top: 5px;
    top: 0.5rem;
    /*.rem(font-size, 10);*/
    right: 10px;
    right: 1rem;
    /*.rem(font-size, 10);*/
    font-size: 20px;
    font-size: 2rem;
    font-weight: 700; }

footer {
  background: #4d4d4d;
  float: left;
  width: 100%;
  /*.rem(margin, 10, 10);*/
  padding: 20px 0px;
  padding: 2rem 0rem;
  color: #fff; }
  footer .desplegable {
    float: left;
    width: 100%;
    /*.rem(margin, 10, 10);*/
    padding: 0px 15px;
    padding: 0rem 1.5rem; }
    footer .desplegable h3 {
      float: left;
      width: 100%;
      /*.rem(margin, 10, 10);*/
      padding: 10px 0px;
      padding: 1rem 0rem;
      margin: 0; }
      footer .desplegable h3 a {
        /*.rem(font-size, 10);*/
        font-size: 14px;
        font-size: 1.4rem;
        color: #fff; }
      footer .desplegable h3 .icon {
        float: right;
        /*.rem(font-size, 10);*/
        margin-right: 5px;
        margin-right: 0.5rem; }
        footer .desplegable h3 .icon:before {
          content: "\E914";
          font-weight: 700; }
    footer .desplegable.abierto h3 .icon:before {
      content: "\E915"; }
    footer .desplegable.abierto ul {
      display: block; }
  footer ul {
    display: none;
    float: left;
    width: 100%; }
    footer ul li {
      float: left;
      width: 100%;
      /*.rem(font-size, 10);*/
      margin-bottom: 10px;
      margin-bottom: 1rem;
      /*.rem(font-size, 10);*/
      font-size: 14px;
      font-size: 1.4rem;
      text-align: left !important; }
      footer ul li a {
        color: #fff;
        opacity: 0.7;
        /*.rem(font-size, 10);*/
        font-size: 14px;
        font-size: 1.4rem; }
        footer ul li a:hover {
          color: #fff;
          opacity: 1; }
  footer p {
    float: left;
    width: 100%;
    /*.rem(font-size, 10);*/
    font-size: 12px;
    font-size: 1.2rem;
    text-align: center;
    margin: 0;
    /*.rem(font-size, 10);*/
    line-height: 12px;
    line-height: 1.2rem;
    opacity: 0.7; }
    footer p span {
      display: block;
      font-weight: 400;
      /*.rem(font-size, 10);*/
      font-size: 12px;
      font-size: 1.2rem;
      /*.rem(font-size, 10);*/
      margin-top: 5px;
      margin-top: 0.5rem; }
    footer p:nth-of-type(1) {
      float: none;
      width: 90%;
      margin: auto;
      /*.rem(font-size, 10);*/
      margin-bottom: 20px;
      margin-bottom: 2rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.7);
      /*.rem(font-size, 10);*/
      padding-bottom: 20px;
      padding-bottom: 2rem;
      opacity: 1; }
  footer img {
    display: block;
    margin: auto;
    /*.rem(font-size, 10);*/
    margin-bottom: 5px;
    margin-bottom: 0.5rem;
    /*.rem(font-size, 10);*/
    padding-top: 10px;
    padding-top: 1rem;
    /*.rem(font-size, 10);*/
    width: 116px;
    width: 11.6rem;
    height: auto; }
    footer img.vocento {
      /*.rem(font-size, 10);*/
      margin-bottom: 10px;
      margin-bottom: 1rem;
      /*.rem(font-size, 10);*/
      width: 91px;
      width: 9.1rem;
      padding: 0; }

#modalSelectFiltro ul li {
  display: inline-block;
  /*.rem(font-size, 10);*/
  margin-bottom: 10px;
  margin-bottom: 1rem;
  width: 50%; }
  #modalSelectFiltro ul li:nth-of-type(1) {
    display: block;
    width: 100%;
    /*.rem(font-size, 10);*/
    margin-bottom: 30px;
    margin-bottom: 3rem;
    border: 1px solid #1976d2;
    border-radius: 3px;
    /*.rem(margin, 10, 10);*/
    padding: 8px 0px;
    padding: 0.8rem 0rem;
    text-align: center; }

#covid-modal img {
  margin: auto;
  /*.rem(font-size, 10);*/
  margin-bottom: 20px;
  margin-bottom: 2rem;
  display: block;
  /*.rem(font-size, 10);*/
  width: 199px;
  width: 19.9rem; }

#covid-modal h2 {
  float: none;
  /*.rem(font-size, 10);*/
  margin-bottom: 20px;
  margin-bottom: 2rem;
  text-align: center; }

#covid-modal p {
  text-align: center;
  /*.rem(margin, 10, 10);*/
  padding: 0px 20px;
  padding: 0rem 2rem;
  /*.rem(font-size, 10);*/
  margin-bottom: 20px;
  margin-bottom: 2rem; }
  #covid-modal p span {
    font-weight: 700; }
    #covid-modal p span:nth-of-type(1) {
      color: #1976d2;
      display: block;
      /*.rem(font-size, 10);*/
      margin-bottom: 10px;
      margin-bottom: 1rem;
      /*.rem(font-size, 10);*/
      font-size: 20px;
      font-size: 2rem; }

#covid-modal .btn {
  float: none;
  margin: auto;
  display: block;
  /*.rem(font-size, 10);*/
  width: 240px;
  width: 24rem; }

.texto-seo, .enlaces-seo {
  float: left;
  width: 100%;
  background: #fff;
  border: 1px solid #fff;
  /*.rem(font-size, 10);*/
  margin-bottom: 20px;
  margin-bottom: 2rem;
  /*.rem(font-size, 10);*/
  padding: 20px;
  padding: 2rem;
  padding-bottom: 0; }
  .texto-seo h3, .texto-seo p, .enlaces-seo h3, .enlaces-seo p {
    float: left;
    width: 100%;
    text-align: center; }
  .texto-seo h3, .enlaces-seo h3 {
    /*.rem(font-size, 10);*/
    margin-bottom: 20px;
    margin-bottom: 2rem;
    /*.rem(font-size, 10);*/
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 700; }
  .texto-seo p, .enlaces-seo p {
    /*.rem(font-size, 10);*/
    line-height: 28px;
    line-height: 2.8rem;
    /*.rem(font-size, 10);*/
    padding-bottom: 20px;
    padding-bottom: 2rem; }
    .texto-seo p span, .enlaces-seo p span {
      font-weight: 700; }
  .texto-seo ul, .enlaces-seo ul {
    float: left;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    justify-content: center; }
    .texto-seo ul li, .enlaces-seo ul li {
      float: left;
      /*.rem(font-size, 10);*/
      margin-right: 10px;
      margin-right: 1rem;
      /*.rem(font-size, 10);*/
      margin-bottom: 20px;
      margin-bottom: 2rem; }
      .texto-seo ul li a, .enlaces-seo ul li a {
        float: left;
        /*.rem(margin, 10, 10);*/
        padding: 5px 10px;
        padding: 0.5rem 1rem;
        border: 1px solid #b6b6b6;
        border-radius: 3px;
        color: #1976d2; }
        .texto-seo ul li a:hover, .enlaces-seo ul li a:hover {
          background: #1976d2;
          border: 1px solid #1976d2;
          color: #fff; }
      .texto-seo ul li:nth-of-type(n+7):not(.ver-mas), .enlaces-seo ul li:nth-of-type(n+7):not(.ver-mas) {
        display: none; }
      .texto-seo ul li:nth-of-type(-n+7).ver-mas, .enlaces-seo ul li:nth-of-type(-n+7).ver-mas {
        display: none; }
      .texto-seo ul li.ver-mas, .enlaces-seo ul li.ver-mas {
        display: block; }
        .texto-seo ul li.ver-mas a, .enlaces-seo ul li.ver-mas a {
          border: 1px solid #1976d2;
          position: relative;
          /*.rem(font-size, 10);*/
          padding-left: 35px;
          padding-left: 3.5rem; }
          .texto-seo ul li.ver-mas a:before, .enlaces-seo ul li.ver-mas a:before {
            content: "+";
            position: absolute;
            /*.rem(font-size, 10);*/
            top: 5px;
            top: 0.5rem;
            /*.rem(font-size, 10);*/
            left: 9px;
            left: 0.9rem;
            background: #1976d2;
            border-radius: 20px;
            color: #fff;
            /*.rem(margin, 10, 10);*/
            padding: 0px 5px;
            padding: 0rem 0.5rem; }
          .texto-seo ul li.ver-mas a:hover:before, .enlaces-seo ul li.ver-mas a:hover:before {
            background: #fff;
            color: #1976d2; }

.banner-publicidad {
  float: left;
  width: 100%;
  background: #e7ebef;
  /*.rem(font-size, 10);*/
  padding: 20px;
  padding: 2rem;
  /*.rem(font-size, 10);*/
  padding-top: 10px;
  padding-top: 1rem; }
  .banner-publicidad a {
    display: block; }
  .banner-publicidad p {
    float: left;
    width: 58%;
    /*.rem(font-size, 10);*/
    margin-bottom: 24px;
    margin-bottom: 2.4rem;
    /*.rem(font-size, 10);*/
    font-size: 20px;
    font-size: 2rem;
    color: #4d4d4d;
    font-weight: 700; }
    .banner-publicidad p span {
      display: block;
      float: left;
      /*.rem(font-size, 10);*/
      width: 175px;
      width: 17.5rem;
      /*.rem(font-size, 10);*/
      margin-top: 4px;
      margin-top: 0.4rem;
      /*.rem(font-size, 10);*/
      font-size: 14px;
      font-size: 1.4rem;
      font-weight: 400; }
  .banner-publicidad .btn {
    float: left;
    width: 100%;
    clear: both;
    /*.rem(margin, 10, 10);*/
    padding: 6px 5px;
    padding: 0.6rem 0.5rem;
    /*.rem(font-size, 10);*/
    height: 30px;
    height: 3rem;
    /*.rem(font-size, 10);*/
    font-size: 14px;
    font-size: 1.4rem; }
  .banner-publicidad img {
    float: right;
    width: auto;
    /*.rem(font-size, 10);*/
    height: 58px;
    height: 5.8rem; }
  .listado .banner-publicidad, .ficha .banner-publicidad {
    background: #fff;
    /*.rem(font-size, 10);*/
    margin-bottom: 40px;
    margin-bottom: 4rem; }
  .ficha .banner-publicidad {
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    /*.rem(margin, 10, 10);*/
    margin: 10px 0px;
    margin: 1rem 0rem; }

/* Capa Cookies */
#didomi-host {
  font-family: 'Lato' !important; }
  #didomi-host .didomi-exterior-border {
    border-color: #1976d2 !important; }
  #didomi-host .didomi-popup-container {
    border: none !important; }
  #didomi-host .didomi-popup-notice .didomi-popup-notice-text p {
    font-size: 14px !important;
    line-height: 160%; }
  #didomi-host .didomi-popup-notice .didomi-notice-view-partners-link-in-text {
    color: #1976d2 !important;
    text-decoration: none !important; }
  #didomi-host .didomi-popup-notice.didomi-popup-notice-with-data-processing {
    /*.rem(font-size, 10);*/
    padding-top: 20px;
    padding-top: 2rem; }
    #didomi-host .didomi-popup-notice.didomi-popup-notice-with-data-processing:before {
      display: block;
      content: url(/assets/css/fonts/logo.f93b5bdd867e17f58bb5b46cda3d04da.svg);
      /*.rem(font-size, 10);*/
      margin-top: -15px;
      margin-top: -1.5rem;
      /*.rem(font-size, 10);*/
      margin-bottom: 20px;
      margin-bottom: 2rem;
      /*.rem(font-size, 10);*/
      width: 200px;
      width: 20rem;
      /*.rem(font-size, 10);*/
      height: 36px;
      height: 3.6rem; }
  #didomi-host .didomi-notice-data-processing-container {
    text-align: left !important;
    font-size: 14px !important; }
    #didomi-host .didomi-notice-data-processing-container p {
      line-height: 150% !important; }
    #didomi-host .didomi-notice-data-processing-container .didomi-notice-data-processing-list {
      font-weight: 400 !important;
      color: #4d4d4d !important; }
  #didomi-host .didomi-popup-notice-optin-type .didomi-popup-notice-buttons .didomi-components-button {
    background: #fff !important;
    color: #1976d2 !important; }
    #didomi-host .didomi-popup-notice-optin-type .didomi-popup-notice-buttons .didomi-components-button.didomi-button.didomi-dismiss-button.didomi-components-button--color.didomi-button-highlight.highlight-button {
      color: #fff !important;
      background: #1976d2 !important; }
  #didomi-host .didomi-mobile #didomi-popup .didomi-popup-notice p {
    text-align: left !important; }
  #didomi-host .didomi-mobile .didomi-notice-data-processing-container .didomi-notice-data-processing-title, #didomi-host .didomi-mobile .didomi-notice-data-processing-container .didomi-notice-data-processing-list {
    font-size: 12px !important;
    line-height: 150%; }
  #didomi-host .didomi-popup-notice .didomi-popup-notice-text, #didomi-host .didomi-popup-notice .didomi-popup-notice-subtext {
    max-width: 100% !important; }
  #didomi-host #didomi-notice-agree-button {
    order: 1; }
  #didomi-host #didomi-notice-learn-more-button {
    order: 2; }
  #didomi-host #didomi-notice-disagree-button {
    order: 3; }
  #didomi-host .didomi-popup-notice-text-container {
    max-height: calc(100vh - 225px - 55px - 40px) !important;
    overflow: auto !important; }

.img-portada {
  position: relative; }
  .img-portada img {
    width: 100%;
    height: auto; }
  .img-portada h1 {
    /*.rem(font-size, 10);*/
    font-size: 28px;
    font-size: 2.8rem;
    color: #fff;
    position: absolute;
    top: 23%;
    left: 0;
    right: 0;
    z-index: 999;
    text-align: center;
    /*.rem(margin, 10, 10);*/
    padding: 0px 5px;
    padding: 0rem 0.5rem; }

.btn {
  /*.rem(font-size, 10);*/
  width: 285px;
  width: 28.5rem;
  margin: auto;
  padding-left: 0;
  padding-right: 0; }

.buscador {
  /*.rem(font-size, 10);*/
  margin: 10px;
  margin: 1rem;
  /*.rem(font-size, 10);*/
  margin-bottom: 20px;
  margin-bottom: 2rem; }
  @media (min-width: 500px) {
    .buscador {
      /*.rem(font-size, 10);*/
      width: 500px;
      width: 50rem;
      margin-right: auto;
      margin-left: auto; } }
  .buscador .radio, .buscador .selectFiltro, .buscador input, .buscador .btn {
    width: 100%;
    /*.rem(font-size, 10);*/
    margin-bottom: 10px;
    margin-bottom: 1rem; }
  .buscador .selectFiltro p {
    /*.rem(font-size, 10);*/
    height: 40px;
    height: 4rem;
    /*.rem(font-size, 10);*/
    padding-top: 10px;
    padding-top: 1rem; }
  .buscador input {
    /*.rem(font-size, 10);*/
    height: 37px;
    height: 3.7rem; }
  .grupo .buscador {
    margin-bottom: 0; }

.grupo {
  float: left;
  width: 100%;
  /*.rem(font-size, 10);*/
  margin-bottom: 20px;
  margin-bottom: 2rem;
  border-top: 1px solid #b6b6b6; }
  .grupo h2, .grupo h3 {
    width: 90%;
    /*.rem(font-size, 10);*/
    font-size: 20px;
    font-size: 2rem;
    margin: auto;
    /*.rem(font-size, 10);*/
    margin-top: 10px;
    margin-top: 1rem;
    /*.rem(font-size, 10);*/
    margin-bottom: 10px;
    margin-bottom: 1rem;
    text-align: center; }
  .grupo ul li {
    display: inline-block;
    width: 49%;
    margin: 0;
    /*.rem(font-size, 10);*/
    margin-bottom: 10px;
    margin-bottom: 1rem;
    text-align: center; }
    .grupo ul li .icon {
      display: block;
      /*.rem(font-size, 10);*/
      font-size: 48px;
      font-size: 4.8rem;
      color: #727272; }
    .grupo ul li img {
      display: block;
      margin: auto;
      /*.rem(font-size, 10);*/
      margin-bottom: 20px;
      margin-bottom: 2rem; }
  .grupo .btn {
    width: 100%; }
  .grupo.texto-seo h3 {
    font-weight: 400; }
  .grupo.precio li {
    background: #e7ebef;
    width: 100%;
    /*.rem(font-size, 10);*/
    padding: 20px;
    padding: 2rem;
    position: relative; }
    .grupo.precio li span {
      font-weight: 700; }
      .grupo.precio li span.num {
        color: #b6b6b6;
        position: absolute;
        /*.rem(font-size, 10);*/
        top: 20px;
        top: 2rem;
        left: 20%;
        font-weight: 400;
        /*.rem(font-size, 10);*/
        font-size: 36px;
        font-size: 3.6rem; }
        @media (min-width: 500px) {
          .grupo.precio li span.num {
            left: 30%; } }

.publicidad {
  float: none;
  /*.rem(font-size, 10);*/
  margin-top: 20px;
  margin-top: 2rem;
  margin-bottom: 0; }
  .publicidad .text-publi {
    float: none;
    margin: auto;
    width: 94%;
    /*.rem(font-size, 10);*/
    margin-bottom: 20px;
    margin-bottom: 2rem; }
  .publicidad > div {
    display: inline-block;
    width: 49%;
    text-align: center; }
